import { applyMiddleware, createStore, compose } from "redux"
import thunk from "redux-thunk"
import rootReducer from "./reducer"
import { getFirebase } from "react-redux-firebase"
import ContentfulMiddleware from "./middleware/ContentfulMiddleware"
import EmailerMiddleware from "./middleware/EmailerMiddleware"

// export default function configureStore (initialState, history) {
export default function configureStore() {
  const middleware = [
    thunk.withExtraArgument({ getFirebase }),
    ContentfulMiddleware,
    EmailerMiddleware,
  ]

  const createStoreWithMiddleware = compose(
    applyMiddleware(...middleware),
    typeof window === "object" &&
      typeof window.devToolsExtension !== "undefined"
      ? () => window.__REDUX_DEVTOOLS_EXTENSION__
      : (f) => f
  )(createStore)

  const store = createStoreWithMiddleware(rootReducer)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducer", () => {
      const nextRootReducer = require("./reducer")
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
