export class MemberLevel {
  constructor(productId) {
    this.productId = productId

    switch (this.productId) {
      case "com.ithazfire.rallista.professional":
        this.level = "professional"
        break
      case "com.ithazfire.rallista.member":
        this.level = "member"
        break
      default:
        this.level = "standard"
    }
  }

  title() {
    switch (this.level) {
      case "professional":
        return "Rallista Pro"
      case "member":
        return "Rallista Member"
      case "staff":
        return "Staff"
      case "standard":
        return "Standard"
      default:
        console.error(`Unknown member level ${this.level}`)
        return "Unknown"
    }
  }

  maxInvites() {
    switch (this.level) {
      case "standard":
        return 5
      case "member":
        return 15
      case "professional":
        return 50
      case "staff":
        return 5000
      default:
        console.error(`Unknown member level ${this.level}`)
        return 5
    }
  }

  maxWaypoints() {
    switch (this.level) {
      case "standard":
        return 24
      case "member":
        return 249
      case "professional":
        return 249
      case "staff":
        return 249
      default:
        console.error(`Unknown member level ${this.level}`)
        return 24
    }
  }

  maxSegments() {
    switch (this.level) {
      case "standard":
        return 1
      case "member":
        return 1
      case "professional":
        return 5
      case "staff":
        return 150
      default:
        console.error(`Unknown member level ${this.level}`)
        return 1
    }
  }

  maxSegmentMeters() {
    switch (this.level) {
      case "standard":
        // 100 miles
        return 160934.4
      case "member":
        // 300 miles
        return 482803.2
      case "professional":
        // 300 miles
        return 482803.2
      case "staff":
        // 2000 miles
        return 3218688
      default:
        console.error(`Unknown member level ${this.level}`)
        return 160934.4
    }
  }

  allowRecording() {
    switch (this.level) {
      case "standard":
        return false
      case "member":
        return true
      case "professional":
        return true
      case "staff":
        return true
      default:
        console.error(`Unknown member level ${this.level}`)
        return false
    }
  }

  allowOffline() {
    switch (this.level) {
      case "standard":
        return false
      case "member":
        return true
      case "professional":
        return true
      case "staff":
        return true
      default:
        console.error(`Unknown member level ${this.level}`)
        return false
    }
  }
}
