const actionPrefix = "@@emailer"

export const SEND_SALES = `${actionPrefix}/SEND_SALES`
export const SEND_SALES_SUCCESS = `${actionPrefix}/SEND_SALES_SUCCESS`
export const SEND_SALES_ERROR = `${actionPrefix}/SEND_SALES_ERROR`

export const SEND_ISSUE = `${actionPrefix}/SEND_ISSUE`
export const SEND_ISSUE_SUCCESS = `${actionPrefix}/SEND_ISSUE_SUCCESS`
export const SEND_ISSUE_ERROR = `${actionPrefix}/SEND_ISSUE_ERROR`

export const SEND_ACCOUNT_RESET_EMAIL = `${actionPrefix}/SEND_ACCOUNT_RESET_EMAIL`
