import { getEntry, getAssetFromSys } from "./ContentfulClient"
import getSections from "./Sections"

// __typename: "ContentfulFeatureSection"
// body: {id: "b8622b5b-e04e-5900-96f8-47cf56283d29", body: "{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data…],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}"}
// buttonLink: "/get-rallista"
// buttonTitle: "Get Rallista Now"
// contentful_id: "1lfJ4pK2sK1C6a4DiztAn5"
// id: "efeb041b-f60f-5d1b-b754-03c1c100a84f"
// justifyImage: "Left"
// media: {id: "8e654790-9eda-5e3a-9fc3-dd2b610e5715", title: "Rallista Map", description: "The Rallista Map lets you quickly and easily create intricate routes.", contentful_id: "4XmvSIe0JZP1TNc6suvDyc", file: Object}
// title: "Navigation for Enthusiasts"

const getContentfulPage = async (id) => {
  const { fields } = await getEntry(id)
  const { sections, seoImage } = fields

  const seoAsset = seoImage ? await getAssetFromSys(seoImage.sys) : null
  const newSeoAsset = seoAsset ? seoAsset.fields : null

  var newSections = []
  if (sections) {
    newSections = await getSections(sections)
  }

  return {
    ...fields,
    sections: newSections,
    seoImage: newSeoAsset,
  }
}

export default getContentfulPage

// const getPage = async (contentful_id) => {
//   if (typeof contentful_id == "undefined") {
//     console.error(
//       "[Middleware.Contentful] No contentful_id provided to getPage!"
//     )
//     store.dispatch({
//       type: types.GET_ERROR,
//       errorMessage:
//         "[Middleware.Contentful] No contentful_id provided to getPage!"
//     })
//     return
//   }
//
//   try {
//     /** Get the outer page entry. */
//     const { fields } = await client.getEntry(contentful_id)
//     const { pageId, isFancy, title, description, sections } = fields
//
//     /** Get the section entries. */
//     const promises = await getSections(sections)
//     const sectionData = promises.filter(promise => promise != null)
//
//     store.dispatch({
//       type: types.GET_COMPLETION,
//       contentful_id: contentful_id,
//       data: {
//         contentful_id: contentful_id,
//         pageId,
//         isFancy,
//         title,
//         description,
//         sections: sectionData
//       }
//     })
//   } catch (error) {
//     console.error("[Contentful.Middleware] getPage() (error):", error)
//
//     store.dispatch({
//       type: types.GET_ERROR,
//       errorMessage: "An error occured getting our latest site data."
//     })
//   }
// }
