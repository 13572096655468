const MessageBuilder = (title, data) => {
  var html = `<h3>${title}</h3>
    <table>
      <tbody>`

  var text = ""

  data.forEach((item) => {
    html += `<tr>
      <td>${item.title}</td><td>${item.value}</td>
    </tr>`

    text += `* ${item.title} - ${item.value}\n`
  })

  const disclaimer = `This is an automated message from the Rallista Website.
    As a Team member you're automatically subscribed. If you'd like to
    opt-out please contact the development team.`

  html += `  </tbody>
    </table>
    <p>${disclaimer}</p>`

  text += disclaimer

  return { html, text }
}

export default MessageBuilder
