import * as types from "../constants/AlertConstants"

export const PresentAlert = (message) => {
  return {
    type: types.PRESENT,
    message: message,
    alertedAt: new Date(),
  }
}

export const DismissAlert = () => {
  return {
    type: types.DISMISS,
  }
}
