import * as types from "../constants/EmailerConstants"
import firebase from "firebase/app"

export const SendSalesEmail = (name, email, phone, message) => {
  return {
    type: types.SEND_SALES,
    email: email,
    phone: phone,
    name: name,
    message: message,
  }
}

export const SendSalesSuccess = () => {
  return {
    type: types.SEND_SALES_SUCCESS,
  }
}

export const SendSalesError = (error) => {
  return {
    type: types.SEND_SALES_ERROR,
    error: error,
  }
}

export const SendIssueEmail = (email, description) => {
  return {
    type: types.SEND_ISSUE,
    email: email,
    description: description,
  }
}

export const SendIssueSuccess = () => {
  return {
    type: types.SEND_ISSUE_SUCCESS,
  }
}

export const SendIssueError = (error) => {
  return {
    type: types.SEND_ISSUE_ERROR,
    error: error,
  }
}

export const SendResetEmail = async (email) => {
  // Send Email if we are not testing
  if (!process.env.JEST_WORKER_ID) {
    await firebase.auth().sendPasswordResetEmail(email)
  }
  return { type: types.SEND_ACCOUNT_RESET_EMAIL }
}
