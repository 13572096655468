import { MemberLevel } from "./member-level.model"

class ProtectedSettings {
  constructor(settings) {
    this.protected = settings
    this.memberLevel = new MemberLevel(settings.memberLevel)
  }
}

class UserAccess {
  constructor(access) {
    this.access = access
  }

  isStaff() {
    return this.access ? this.access.isStaff : false
  }
}

export class User {
  constructor(userDocument, settings, protectedValues, access) {
    this._userDocument = userDocument
    this._settings = settings
    this._protected = protectedValues
      ? new ProtectedSettings(protectedValues)
      : null
    this._access = new UserAccess(access)
  }

  get access() {
    return this._access
  }

  get settings() {
    return this._settings
  }

  get protectedValues() {
    return this._protected
  }

  get document() {
    return this._userDocument
  }
}
