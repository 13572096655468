import { createClient } from "contentful"

export const ContentfulClient = createClient({
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
})

export const getEntry = async (id) => {
  if (typeof id == "undefined") {
    throw new Error("Cannot get this Entry because no id was provided.")
  }

  return await ContentfulClient.getEntry(id)
}

export const getAsset = async (id) => {
  if (typeof id == "undefined") {
    throw new Error("Cannot get this Asset because no id was provided.")
  }

  return await ContentfulClient.getAsset(id)
}

export const getEntryFromSys = async (sys) => {
  const { id } = sys

  return getEntry(id)
}

export const getAssetFromSys = async (sys) => {
  const { id } = sys

  return getAsset(id)
}

export default ContentfulClient
