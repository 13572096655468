import React from "react"
import PropTypes from "prop-types"
import { useDb } from "../hooks/useDb"
import { useFirebase } from "react-redux-firebase"

export const UserContext = React.createContext(undefined)
export const AuthContext = React.createContext(undefined)

export const UserContextProvider = ({
  children,
  userContext,
  authContext,
  renderImmediately = false,
}) => {
  const firebase = useFirebase()
  const { updateUser, fetchUser, createUser, usernameExists } = useDb()
  const [userFetchDisabled, setUserFetchDisabled] = React.useState(false)
  const [userState, setUserState] = React.useState({
    user: null,
    authUser: firebase?.auth().currentUser,
    authInitialized: false,
  })

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (newUser) => {
      if (userFetchDisabled) {
        return
      }
      if (newUser !== null && !newUser.isAnonymous && newUser.uid) {
        try {
          await updateUser(newUser.uid)
          const user = await fetchUser(newUser.uid)
          setUserState({
            user,
            authUser: firebase.auth().currentUser,
            authInitialized: true,
          })
        } catch (err) {
          if (firebase.auth().currentUser === null) {
            // Don't log an error if the user has signed out
            return
          } else {
            console.error(
              `Error retrieving user info for ${newUser.uid} as ${
                firebase.auth().currentUser.uid
              }`,
              err
            )
          }
        }
      } else {
        setUserState({
          user: null,
          authUser: firebase.auth().currentUser,
          authInitialized: true,
        })
      }
    })

    return () => {
      unsubscribe()
    }
  }, [fetchUser, firebase, setUserState, updateUser, userFetchDisabled])

  const signInAnonymously = React.useCallback(async () => {
    await firebase.auth().signInAnonymously()
  }, [firebase])

  const signIn = React.useCallback(
    async (email, password) => {
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password)
      } catch (err) {
        if (err.code === "auth/wrong-password") {
          throw new Error("Invalid credentials")
        } else if (err.code === "auth/user-not-found") {
          throw new Error("Invalid credentials")
        }
        console.error(err)
        throw new Error("An unknown error occurred")
      }
    },
    [firebase]
  )

  const signOut = React.useCallback(async () => {
    await firebase.logout()
  }, [firebase])

  const signUp = React.useCallback(
    async (email, password, username) => {
      if (userState.authUser && !userState.authUser.isAnonymous) {
        await signOut()
        await signInAnonymously()
      } else if (!userState.authUser) {
        await signInAnonymously()
      }

      if (await usernameExists(username)) {
        throw new Error("That username is already in use, please try another")
      }

      setUserFetchDisabled(true)

      try {
        await firebase.auth().createUserWithEmailAndPassword(email, password)
      } catch (err) {
        setUserFetchDisabled(false)
        if (err.code === "auth/email-already-in-use") {
          throw new Error("An account already exists for that email")
        } else if (err.code === "auth/invalid-email") {
          throw new Error("Email address supplied is not a valid email address")
        } else if (err.code === "auth/weak-password") {
          throw new Error(
            "Password supplied does not meet minimum security requirements"
          )
        } else {
          console.error(err)
          throw new Error("An unknown error occurred")
        }
      }

      try {
        const { uid } = firebase.auth().currentUser
        const user = await createUser(uid, { email, username })
        setUserState({
          user,
          authUser: firebase.auth().currentUser,
        })
      } catch (err) {
        console.error(err)
        throw new Error("An unknown error occurred")
      } finally {
        setUserFetchDisabled(false)
      }
    },
    [signOut]
  )

  return (
    <authContext.Provider
      value={{ signIn, signInAnonymously, signUp, signOut }}
    >
      <userContext.Provider value={userState}>
        {userState.authInitialized || renderImmediately ? children : null}
      </userContext.Provider>
    </authContext.Provider>
  )
}

UserContextProvider.propTypes = {
  children: PropTypes.node,
  userContext: PropTypes.object,
  authContext: PropTypes.object,
}

UserContextProvider.defaultProps = {
  userContext: UserContext,
  authContext: AuthContext,
}

export const useUserContext = () => {
  const context = React.useContext(UserContext)

  if (context === undefined) {
    throw new Error("useUserContext must be used within UserContextProvider")
  }

  return context
}

export const useAuthContext = () => {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error("useAuthContext must be used within UserContextProvider")
  }

  return context
}
