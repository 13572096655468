import getContentfulPage from "./Contentful/ContentfulPage"
import * as types from "../constants/ContentfulConstants"
import { GetPageComplete, GetPageError } from "../actions/ContentfulActions"

/**
 * The ContentfulMiddleware is used to update data from the Contentful REST API
 */
export const ContentfulMiddleware = (store) => (next) => (action) => {
  const getPage = async (id) => {
    try {
      const page = await getContentfulPage(id)

      store.dispatch(GetPageComplete(page.rallistaId, page))
    } catch (error) {
      store.dispatch(GetPageError(error))
    }
  }

  /** Action Stack */
  if (action.type === types.GET_PAGE) {
    getPage(action.id)
  }
  next(action)
}

export default ContentfulMiddleware
