import getContentfulFeatureSection from "./ContentfulFeatureSection"
import getContentfulHeroSection from "./ContentfulHeroSection"
import getContentfulTextSection from "./ContentfulTextSection"

const getSections = (sections) => {
  const promises = sections.map(async (section) => {
    const { type } = section.internal

    if (type === undefined) {
      console.error("Section does not have a contentType", section)
      return section
    }

    switch (type) {
      case "featureSection":
        return { ...(await getContentfulFeatureSection(section)) }
      case "heroSection":
        return { ...(await getContentfulHeroSection(section)) }
      case "textSection":
        return { ...getContentfulTextSection(section) }
      default:
        console.error("Invalid section", section)
    }
  })

  return Promise.all(promises)
}

export default getSections
