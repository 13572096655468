import { getEntryFromSys, getAssetFromSys } from "./ContentfulClient"

// __typename: "ContentfulHeroSection"
// actionBox: null
// background: {id: "a7fe4cbb-d4bc-589d-8a77-478ddc66933f", file: Object, contentful_id: "1ESkIYgW7c2iD67j8ADFVH", title: "Rallista in the Wild", description: "Some of the best driving roads."}
// contentful_id: "3uT5bR4k48OR8lEyTeCPMA"
// id: "1b7cb853-8b3f-5546-9d49-dc894a7ace9c"
// justifyActionBox: "Right"
// subTitle: "Navigation for Enthusiasts"
// title: "Rallista"
// titleImage: {id: "4040c7d1-cbc8-52e8-b185-98504306b14d", file: Object, contentful_id: "574Z6Q49JRxW0DuSY7BcJm", title: "The Rallista Logo", description: "The gigantic size Rallista text Logo."}

const getContentfulHeroSection = async (section) => {
  const { fields } = section

  const {
    title,
    subTitle,
    titleImage,
    justifyActionBox,
    actionBox,
    background,
  } = fields

  const actionEntry = actionBox ? await getEntryFromSys(actionBox.sys) : null
  const newActionEntry = actionEntry ? actionEntry.fields : null

  const titleAsset = titleImage ? await getAssetFromSys(titleImage.sys) : null
  const newTitleAsset = titleAsset ? titleAsset.fields : null

  const bgAsset = background ? await getAssetFromSys(background.sys) : null
  const newBgAsset = bgAsset ? bgAsset.fields : null

  return {
    internal: { type: "ContentfulHeroSection" },
    title,
    subTitle,
    justifyActionBox,
    titleImage: newTitleAsset,
    actionBox: newActionEntry,
    background: newBgAsset,
  }
}

export default getContentfulHeroSection
