import { getAssetFromSys } from "./ContentfulClient"

// __typename: "ContentfulFeatureSection"
// body: {id: "b8622b5b-e04e-5900-96f8-47cf56283d29", body: "{\"data\":{},\"content\":[{\"data\":{},\"content\":[{\"data…],\"nodeType\":\"paragraph\"}],\"nodeType\":\"document\"}"}
// buttonLink: "/get-rallista"
// buttonTitle: "Get Rallista Now"
// contentful_id: "1lfJ4pK2sK1C6a4DiztAn5"
// id: "efeb041b-f60f-5d1b-b754-03c1c100a84f"
// justifyImage: "Left"
// media: {id: "8e654790-9eda-5e3a-9fc3-dd2b610e5715", title: "Rallista Map", description: "The Rallista Map lets you quickly and easily create intricate routes.", contentful_id: "4XmvSIe0JZP1TNc6suvDyc", file: Object}
// title: "Navigation for Enthusiasts"

const getContentfulFeatureSection = async (section) => {
  const { fields } = section

  const { title, body, buttonLink, buttonTitle, justifyImage, media } = fields

  const asset = await getAssetFromSys(media.sys)
  const newAsset = asset ? asset.fields : null

  return {
    internal: { type: "ContentfulFeatureSection" },
    title,
    body: { json: body },
    buttonLink,
    buttonTitle,
    justifyImage,
    media: newAsset,
  }
}

export default getContentfulFeatureSection
