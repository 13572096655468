import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Col from "react-bootstrap/Col"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"

const GalleryImage = ({
  fluid,
  file,
  title,
  description,
  to,
  contained,
  src,
}) => {
  const container = contained ? "gallery-contained" : ""

  const [expanded, setExpanded] = useState(false)

  const expandAction = () => {
    setExpanded(!expanded)
  }

  const outerClass = expanded ? "full-screen-img" : ""
  const close = expanded ? (
    <div className="full-screen-close">
      <FontAwesomeIcon icon={faTimesCircle} />
    </div>
  ) : null

  var image = ""

  if (src !== undefined) {
    image = (
      <div className="gallery-image">
        <img className="img-fluid" src={src} alt={description} />
      </div>
    )
  }

  if (file !== undefined) {
    image = (
      <div className="gallery-image">
        <img className="img-fluid" src={file.url} alt={description} />
      </div>
    )
  }

  if (fluid !== undefined) {
    image = <Img className="gallery-image" fluid={fluid} />
  }

  var inner = (
    <div className="gallery-item">
      {image}
      <div className={container}>
        <span className="gallery-title">{title}</span>
        <br />
        <span className="gallery-description">{description}</span>
      </div>
    </div>
  )

  var children = (
    <div className={outerClass} onClick={expandAction}>
      {close}
      {inner}
    </div>
  )

  if (to) {
    children = <Link to={to}>{inner}</Link>
  }

  return (
    <Col xs={12} sm={6} md={4} className="gallery-item">
      {children}
    </Col>
  )
}

GalleryImage.propTypes = {
  fluid: PropTypes.object,
  file: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  to: PropTypes.string,
  contained: PropTypes.bool,
  expand: PropTypes.bool,
}

export default GalleryImage
