import * as types from "../constants/EmailerConstants"

export const initialState = {
  isSendingSales: false,
  salesSent: false,
  salesError: null,
  resetSent: false,
  isSendingIssue: false,
  issueSent: false,
  issueError: null,
}

const EmailerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEND_SALES:
      return {
        ...state,
        isSendingSales: true,
      }
    case types.SEND_SALES_SUCCESS:
      return {
        ...state,
        isSendingSales: false,
        salesSent: true,
        salesError: null,
      }
    case types.SEND_SALES_ERROR:
      return {
        ...state,
        isSendingSales: false,
        salesSent: true,
        salesError: action.error,
      }
    case types.SEND_ISSUE:
      return {
        ...state,
        isSendingIssue: true,
      }
    case types.SEND_ISSUE_SUCCESS:
      return {
        ...state,
        isSendingIssue: false,
        issueSent: true,
        issueError: null,
      }
    case types.SEND_ISSUE_ERROR:
      return {
        ...state,
        isSendingIssue: false,
        issueSent: true,
        issueError: action.error,
      }
    case types.SEND_ACCOUNT_RESET_EMAIL:
      return {
        ...state,
        resetSent: true,
      }
    default:
      return state
  }
}

export default EmailerReducer
