/* eslint-disable react-hooks/rules-of-hooks */
import React from "react"
import { useFirebase } from "react-redux-firebase"
import { User } from "../models/user.model"

const preventNonTestEnvironment = (func) => {
  return (...params) => {
    if (!process.env.JEST_WORKER_ID) {
      throw new Error("Cannot use this function outside of tests")
    }
    return func(...params)
  }
}

const fetchAdditionalDriveFields = async (storage, driveData) => {
  let downloadPhotoUrl
  if (driveData.photoUrl) {
    try {
      downloadPhotoUrl = await storage
        .ref()
        .child(driveData.photoUrl)
        .getDownloadURL()
    } catch (err) {
      downloadPhotoUrl = undefined
    }
  }

  return {
    ...driveData,
    downloadPhotoUrl,
  }
}

const createDriveDocument = (userDocumentId, username, driveInfo) => {
  if (driveInfo.waypoints.length < 2) {
    throw new Error("Unable to save drive without at least two waypoints")
  }
  const startLocation = [driveInfo.waypoints[0].lng, driveInfo.waypoints[0].lat]
  const route = {
    route: {
      distance: driveInfo.distance,
      expectedTravelTime: driveInfo.expectedTravelTime,
      polyline: driveInfo.polyline,
    },
    waypoints: driveInfo.waypoints.map((w, index) => ({
      location: [w.lng, w.lat],
      waypointType:
        index === 0 || index === driveInfo.waypoints.length - 1
          ? "stop"
          : "thru",
    })),
  }

  return {
    userDocumentID: userDocumentId,
    version: 2,

    title: driveInfo.title,
    username: username,
    description: driveInfo.description || null,
    roadType: null,
    learnMoreLink: null,

    isPublic: driveInfo.isPublic,
    isFlagged: false,
    isFeatured: false,

    route,
    startLocation,

    lastSavedAt: new Date(),

    sectionDocumentID: null,
    membershipDocumentID: null,
    priority: null,
    releaseDate: null,
    createdBy: null,
  }
}

const _saveModel = async (firestore, model) => {
  const collection = model.collection()

  if (!model.documentID) {
    const document = firestore.collection(collection).doc()
    collection.documentID = document.id
  }

  const docToSave = model.toFirebaseDocument()

  // Sanitize document
  for (const key in docToSave) {
    if (docToSave[key] === undefined) {
      delete docToSave[key]
    }
  }

  await firestore
    .collection(collection)
    .doc(model.documentID)
    .set(docToSave, { merge: true })

  const subDocuments = model.getSubdocuments()

  if (subDocuments.length === 0) {
    return
  }

  for (const subDocument of subDocuments) {
    await firestore
      .collection(collection)
      .doc(subDocument.key)
      .set(subDocument.document, { merge: true })
  }
}

const _getUser = async (firestore, uid) => {
  try {
    const [user, settings, protectedSettings, access] = await Promise.all([
      firestore.collection("users").doc(uid).get(),
      firestore.collection(`users/${uid}/settings`).doc("settings").get(),
      firestore.collection(`users/${uid}/protected`).doc("protected").get(),
      firestore.collection(`users/${uid}/protected`).doc("access").get(),
    ])
    if (!user.exists) {
      throw Error("User does not exist")
    }

    const settingsData = settings.exists ? settings.data() : null
    const protectedData = protectedSettings.exists
      ? protectedSettings.data()
      : null
    const accessData = access.exists ? access.data() : null

    return new User(user.data(), settingsData, protectedData, accessData)
  } catch (err) {
    throw new Error(
      `Error retrieving user details for uid ${uid} ${err.toString()}`
    )
  }
}

const _fetchDrives = async (firestore, storage, currentUser) => {
  let drivesRef = firestore.collection("drives")

  if (!currentUser.access.isStaff()) {
    drivesRef = drivesRef.where(
      "userDocumentID",
      "==",
      currentUser.document.documentID
    )
  }

  const result = await drivesRef.get()

  const drives = await Promise.all(
    result.docs.map((doc) => fetchAdditionalDriveFields(storage, doc.data()))
  )

  return drives
}

const _putUserDocuments = async (firestore, uid, { username, email }) => {
  try {
    await firestore.collection("users").doc(uid).set({
      documentID: uid,
      username,
      joined: new Date(),
      version: 2,
    })

    await Promise.all([
      firestore
        .collection("users")
        .doc(uid)
        .collection("settings")
        .doc("settings")
        .set({
          documentID: "settings",
          userDocumentID: uid,
        }),
      firestore
        .collection("users")
        .doc(uid)
        .collection("protected")
        .doc("protected")
        .set({
          documentID: "protected",
          userDocumentID: uid,
          version: 2,
          email,
        }),
    ])

    return await _getUser(firestore, uid)
  } catch (err) {
    console.error("Error creating user", err)
  }
}

const _updateUserDocuments = async (firestore, uid, options = {}) => {
  try {
    await firestore
      .collection("users")
      .doc(uid)
      .update({
        ...(options.user || {}),
        documentID: uid,
        version: 2,
      })

    await Promise.all([
      firestore
        .collection("users")
        .doc(uid)
        .collection("settings")
        .doc("settings")
        .update({
          ...(options.settings || {}),
          userDocumentID: uid,
        }),
      firestore
        .collection("users")
        .doc(uid)
        .collection("protected")
        .doc("protected")
        .update({
          ...(options.protected || {}),
          userDocumentID: uid,
          version: 2,
        }),
    ])
  } catch (err) {
    console.error(`Error updating user ${uid}`, err)
  }
}

const _getDrive = async (firestore, storage, driveId) => {
  const drive = await firestore.collection("drives").doc(driveId).get()
  if (!drive.exists) {
    return false
  }

  const driveData = await fetchAdditionalDriveFields(storage, drive.data())

  return {
    ...driveData,
  }
}

const _putDrive = async (firestore, storage, currentUser, driveInfo) => {
  const driveDoc = createDriveDocument(
    currentUser.document.documentID,
    currentUser.document.username,
    driveInfo
  )
  driveDoc.created = new Date()
  const driveRef = await firestore.collection("drives").add(driveDoc)
  await firestore
    .collection("drives")
    .doc(driveRef.id)
    .update({ documentID: driveRef.id })

  const updatedDoc = await _getDrive(firestore, storage, driveRef.id)
  if (!updatedDoc) {
    throw new Error("Failed to create drive. Document not updated properly")
  }
  return updatedDoc
}

const _updateDriveDocument = async (firestore, storage, driveId, driveInfo) => {
  if (!driveId) {
    throw new Error("Cannot update document without supplied driveId")
  }

  if (!driveInfo.userDocumentID || !driveInfo.username) {
    throw new Error(
      `Drive document does not have a ${
        !driveInfo.userDocumentID ? "userDocumentID" : "username"
      }`
    )
  }

  const driveDoc = createDriveDocument(
    driveInfo.userDocumentID,
    driveInfo.username,
    driveInfo
  )
  await firestore.collection("drives").doc(driveId).update(driveDoc)

  const updatedDoc = await _getDrive(firestore, storage, driveId)
  if (!updatedDoc) {
    throw new Error("Failed to create drive. Document not updated properly")
  }
  return updatedDoc
}

const _setPhotoUrlOnDrive = async (firestore, driveId, imagePath) => {
  await firestore.collection("drives").doc(driveId).update({
    photoUrl: imagePath,
  })
}

const _getRegistration = async (
  firestore,
  parentCollection,
  parentId,
  registrationId
) => {
  const regRef = await firestore
    .collection(`${parentCollection}/${parentId}/registrations`)
    .doc(registrationId)
    .get()

  if (!regRef.exists) {
    return null
  }

  return regRef.data()
}

export function useDb() {
  if (typeof window === "undefined") {
    return {}
  }

  const firebase = useFirebase()
  const firestore = firebase.firestore()
  const storage = firebase.storage()

  const createUser = React.useCallback(
    async function createUser(uid, params) {
      return _putUserDocuments(firestore, uid, params)
    },
    [firestore]
  )

  const updateUser = React.useCallback(
    async function updateUser(uid, options = {}) {
      return _updateUserDocuments(firestore, uid, options)
    },
    [firestore]
  )

  const fetchUser = React.useCallback(
    async (uid) => {
      return _getUser(firestore, uid)
    },
    [firestore]
  )

  const fetchDrive = React.useCallback(
    async (driveId) => {
      return _getDrive(firestore, storage, driveId)
    },
    [firestore, storage]
  )

  const createDrive = React.useCallback(
    async (currentUser, driveInfo) => {
      return _putDrive(firestore, storage, currentUser, driveInfo)
    },
    [firestore, storage]
  )

  const usernameExists = React.useCallback(
    async (username) => {
      const usersWithUsername = await firestore
        .collection("users")
        .where("username", "==", username)
        .get()

      return !usersWithUsername.empty
    },
    [firestore]
  )

  const updateDrive = React.useCallback(
    async (driveId, driveInfo) => {
      return _updateDriveDocument(firestore, storage, driveId, driveInfo)
    },
    [firestore, storage]
  )

  const addImageToDrive = React.useCallback(
    async (driveId, imagePath) => {
      await _setPhotoUrlOnDrive(firestore, driveId, imagePath)
    },
    [firestore]
  )

  const fetchDrives = React.useCallback(
    async (currentUser) => {
      return _fetchDrives(firestore, storage, currentUser)
    },
    [firestore, storage]
  )

  const saveModel = React.useCallback(
    (model) => {
      return _saveModel(firestore, model)
    },
    [firestore]
  )

  const generateDocId = React.useCallback(
    (collection) => {
      return firestore.collection(collection).doc().id
    },
    [firestore]
  )

  const getDownloadUrl = React.useCallback(
    (path) => {
      return storage.ref().child(path).getDownloadURL()
    },
    [storage]
  )

  const fetchRegistration = React.useCallback(
    (parentCollection, parentId, registrationId) => {
      return _getRegistration(
        firestore,
        parentCollection,
        parentId,
        registrationId
      )
    },
    [firestore]
  )

  return {
    addImageToDrive,
    getDownloadUrl,
    createUser,
    fetchDrive,
    createDrive,
    fetchUser,
    updateUser,
    updateDrive,
    usernameExists,
    fetchDrives,
    saveModel,
    generateDocId,
    fetchRegistration,
  }
}

export const getUser = preventNonTestEnvironment(_getUser)
export const putUserDocuments = preventNonTestEnvironment(_putUserDocuments)
export const putDrive = preventNonTestEnvironment(_putDrive)
export const getDrive = preventNonTestEnvironment(_getDrive)
export const updateDriveDocument =
  preventNonTestEnvironment(_updateDriveDocument)
export const updateUserDocuments =
  preventNonTestEnvironment(_updateUserDocuments)
export const setPhotoUrlOnDrive = preventNonTestEnvironment(_setPhotoUrlOnDrive)
export const fetchDrives = preventNonTestEnvironment(_fetchDrives)
