const getContentfulTextSection = (section) => {
  const { fields } = section
  const { richText } = fields

  return {
    internal: { type: "ContentfulTextSection" },
    ...fields,
    richText: { raw: richText },
  }
}

export default getContentfulTextSection
