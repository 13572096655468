import { combineReducers } from "redux"
import AlertReducer from "./reducers/AlertReducer"
import AuthReducer from "./reducers/AuthReducer"
import ContentfulReducer from "./reducers/ContentfulReducer"
import EmailerReducer from "./reducers/EmailerReducer"
import { firebaseReducer } from "react-redux-firebase"
import { firestoreReducer } from "redux-firestore"

const rootReducer = combineReducers({
  alert: AlertReducer,
  auth: AuthReducer,
  contentful: ContentfulReducer,
  emailer: EmailerReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
})

export default rootReducer
