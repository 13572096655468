import * as types from "../constants/AlertConstants"

export const initialState = {
  showAlert: false,
  alertMessage: "",
  alertedAt: null,
}

const AlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PRESENT:
      return {
        ...state,
        showAlert: true,
        alertMessage: action.message,
        alertedAt: action.alertedAt,
      }
    case types.DISMISS:
      return {
        ...state,
        showAlert: false,
        alertMessage: "",
        alertedAt: null,
      }
    default:
      return state
  }
}

export default AlertReducer
