import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import "firebase/storage"
import { firebaseConfig } from "../config"

let Firebase = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app()

if (process.env.JEST_WORKER_ID !== undefined) {
  // @ts-ignore
  Firebase.auth().useEmulator(`http://localhost:9099`, {
    disableWarnings: true,
  })
  Firebase.firestore().useEmulator(`localhost`, 8089)
}

export default Firebase
