import * as types from "../constants/AuthConstants"
import { actionTypes as reduxFirebaseActions } from "react-redux-firebase"

const firebaseActions =
  typeof reduxFirebaseActions !== "undefined" ? reduxFirebaseActions : {}

const initialState = {
  isAuthorizing: true,
  isAuthorized: false,
  email: "",
  password: "",
  resetEmail: "",
}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case firebaseActions.AUTHENTICATION_INIT_STARTED:
      return {
        ...state,
        isAuthorizing: true,
      }
    case firebaseActions.LOGIN:
      return {
        ...state,
        isAuthorizing: false,
        isAuthorized: true,
      }
    case firebaseActions.LOGIN_ERROR:
      return {
        ...state,
        isAuthorizing: false,
        isAuthorized: false,
      }
    case firebaseActions.AUTH_EMPTY_CHANGE:
      return {
        ...state,
        isAuthorizing: false,
        isAuthorized: false,
      }
    case types.SET_AUTH_FORM:
      return {
        ...state,
        [action.key]: action.value,
      }
    case types.RESET_AUTH:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}

export default AuthReducer
