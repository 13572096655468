import Firebase from "../middleware/Firebase"
import * as types from "../constants/EmailerConstants"
import MessageBuilder from "../helpers/MessageBuilder"
import * as EmailerActions from "../actions/EmailerActions"
import * as AlertActions from "../actions/AlertActions"

/**
 * The FirebaseMiddleware is used to update data from the Contentful REST API
 */
export const FirebaseMiddleware = (store) => (next) => (action) => {
  const sendEmail = Firebase.functions().httpsCallable("sendEmail")

  // sendEmail({subject: messageText, body: html}).then(function(result) {
  //   // Read result of the Cloud Function.
  //   var sanitizedMessage = result.data.text
  // }).catch(function(error) {
  //   // Getting the Error details.
  //   var code = error.code
  //   var message = error.message
  //   var details = error.details
  //   // ...
  // })

  const sendSales = async ({ email, fullname, phone, message }) => {
    try {
      const ts = new Date().toISOString()
      const title = `New Sales Request ${ts}`

      const { html, text } = MessageBuilder(title, [
        { title: "Full Name", value: fullname },
        { title: "Email", value: email },
        { title: "Phone", value: phone },
        { title: "Message", value: message },
      ])

      await sendEmail({
        subject: title,
        body: html,
        text: text,
      })

      store.dispatch(EmailerActions.SendSalesSuccess())
    } catch (error) {
      console.error("Emailer.sendSales() (error):", error)

      store.dispatch(EmailerActions.SendSalesError(error))
      store.dispatch(
        AlertActions.PresentAlert(
          "The message didn't send.. Try info@rallista.app."
        )
      )
    }
  }

  const sendIssue = async ({ email, description }) => {
    try {
      const ts = new Date().toISOString()
      const title = `New Issue Report ${ts}`

      const { html, text } = MessageBuilder(title, [
        { title: "Email", value: email },
        { title: "Description", value: description },
      ])

      await sendEmail({
        subject: title,
        body: html,
        text: text,
      })

      store.dispatch(EmailerActions.SendIssueSuccess())
    } catch (error) {
      console.error("Emailer.sendIssue() (error):", error)

      store.dispatch(EmailerActions.SendIssueError(error))
      store.dispatch(
        AlertActions.PresentAlert(
          "The message didn't send.. Try info@rallista.app."
        )
      )
    }
  }

  const { emailer } = store.getState()
  const { salesSent, issueSent } = emailer

  /** Action Stack */
  switch (action.type) {
    case types.SEND_SALES:
      if (salesSent) {
        store.dispatch(
          AlertActions.PresentAlert(
            "You've already sent a sales message. Try again in a bit."
          )
        )
        break
      }
      sendSales(action)
      break
    case types.SEND_ISSUE:
      if (issueSent) {
        store.dispatch(
          AlertActions.PresentAlert(
            "You've already sent feedback. Try again in a bit."
          )
        )
        break
      }
      sendIssue(action)
      break
    default:
      next(action)
  }

  next(action)
}

export default FirebaseMiddleware
