import React from "react"
import { Provider } from "react-redux"
import { ReactReduxFirebaseProvider } from "react-redux-firebase"
import { createFirestoreInstance } from "redux-firestore"
import { UserContextProvider } from "./src/context/UserContext"
import configureStore from "./src/store"
import Firebase from "./src/middleware/Firebase"
import { rrfConfig } from "./src/config"

// eslint-disable-next-line import/no-anonymous-default-export
// eslint-disable-next-line react/display-name
export default function WrapProviders({ element }) {
  const store = configureStore()

  if (typeof window !== "undefined") {
    return (
      <Provider store={store}>
        <ReactReduxFirebaseProvider
          firebase={Firebase}
          config={rrfConfig}
          dispatch={store.dispatch}
          createFirestoreInstance={createFirestoreInstance}
        >
          <UserContextProvider renderImmediately={true}>
            {element}
          </UserContextProvider>
        </ReactReduxFirebaseProvider>
      </Provider>
    )
  }

  return (
    <Provider store={store}>
      <UserContextProvider renderImmediately={true}>
        {element}
      </UserContextProvider>
    </Provider>
  )
}
