import * as types from "../constants/ContentfulConstants"

const initialState = {
  error: null,
  pages: {},
}

const ContentfulReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COMPLETE:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.rallistaId]: action.page,
        },
      }
    case types.GET_ERROR:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}

export default ContentfulReducer
