import * as types from "../constants/ContentfulConstants"

export const GetPage = (id) => {
  return {
    type: types.GET_PAGE,
    id: id,
  }
}

export const GetPageComplete = (rallistaId, page) => {
  return {
    type: types.GET_COMPLETE,
    rallistaId: rallistaId,
    page: page,
  }
}

export const GetPageError = (error) => {
  return {
    type: types.GET_ERROR,
    error: error,
  }
}
